.twitter-typeahead {
  display: block !important;
}
.twitter-typeahead .tt-menu {
  float: left;
  position: absolute;
  left: 0;
  top: 100%;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  display: none;
}
[dir=rtl] .twitter-typeahead .tt-menu {
  float: right;
  left: auto !important;
  right: 0 !important;
  text-align: right;
}
.twitter-typeahead .tt-menu .tt-suggestion {
  text-align: inherit;
  border: 0;
  width: 100%;
  display: block;
  white-space: nowrap;
  background: none;
  clear: both;
  cursor: pointer;
}
.twitter-typeahead .tt-menu .tt-suggestion p {
  margin: 0;
}
.twitter-typeahead .tt-hint {
  color: #999;
}
[dir=rtl] .twitter-typeahead .tt-input {
  direction: rtl;
}

.light-style .twitter-typeahead .tt-menu {
  min-width: 12rem;
  padding: 0.3125rem 0;
  font-size: 0.9375rem;
  z-index: 1000;
  margin: 0.125rem 0;
  color: #697a8d;
  box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, 0.45);
  background-color: #fff;
  border-radius: 0.375rem;
}
.light-style .twitter-typeahead .tt-hint {
  color: #b4bdc6;
}
.light-style .twitter-typeahead .tt-suggestion {
  font-weight: 400;
  color: #697a8d;
  padding: 0.532rem 1.25rem;
}
.light-style .twitter-typeahead .tt-suggestion:hover, .light-style .twitter-typeahead .tt-suggestion:focus {
  text-decoration: none;
  color: #5f6e7f;
  background-color: rgba(67, 89, 113, 0.04);
}

.dark-style .twitter-typeahead .tt-menu {
  color: #a3a4cc;
  min-width: 12rem;
  padding: 0.3125rem 0;
  margin: 0.125rem 0;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
  font-size: 0.9375rem;
  background-color: #323249;
  z-index: 1000;
  border-radius: 0.375rem;
}
.dark-style .twitter-typeahead .tt-menu .tt-suggestion {
  font-weight: 400;
  color: #a3a4cc;
  padding: 0.532rem 1.25rem;
}
.dark-style .twitter-typeahead .tt-menu .tt-suggestion:hover, .dark-style .twitter-typeahead .tt-menu .tt-suggestion:focus {
  text-decoration: none;
  color: #9394b8;
  background-color: rgba(124, 125, 182, 0.1);
}
.dark-style .twitter-typeahead .tt-hint {
  color: #7c7db6;
}
